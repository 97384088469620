import { createSlice, current } from "@reduxjs/toolkit";





const initialState = () => ({
    currentChain: {},
    chainList: [
        {
            name: 'Polygon',
            id: 137,
            chainId: 137,
            icon: 'polygon',
            slug: 'polygon',
            nativeCurrency: {
                name: 'Polygon',
                symbol: 'POL',
                decimals: 18,
            },
            stableToken: {
                name: 'USDT',
                symbol: 'USDT',
                decimals: 6,
            },
            rpcUrl: 'https://polygon-bor-rpc.publicnode.com',
            image: 'polygon.png',
            domainEnding: 'poly',
            animal: 'fox',
            explorer: 'https://polygonscan.com',
            scan: {
                coin: {
                    name: 'Pluri',
                    url: 'https://polygonscan.com/token/0xe469e49A15cF9c6B0C2685027eBb8bE43363bE33',
                    address: '0xe469e49A15cF9c6B0C2685027eBb8bE43363bE33'
                },
                faucet: {
                    name: 'Faucet',
                    url: 'https://polygonscan.com/address/0xd75a1a4b3B1D8B8b135fc4d18dB880FB30492b62',
                    address: '0xd75a1a4b3B1D8B8b135fc4d18dB880FB30492b62'
                },
                stakeholder: {
                    name: 'Stakeholder',
                    url: 'https://polygonscan.com/address/0xF31aB3088cc898F8e361e4e2D1a49a42EE6EEC37',
                    address: '0xF31aB3088cc898F8e361e4e2D1a49a42EE6EEC37'
                }
            }
        },
        {
            name: 'Binance',
            id: 56,
            chainId: 56,
            icon: 'binance',
            slug: 'binance',
            nativeCurrency: {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18,
            },
            stableToken: {
                name: 'USDT',
                symbol: 'USDT',
                decimals: 6,
            },
            rpcUrl: 'https://bsc-rpc.publicnode.com',
            image: 'binance.png',
            domainEnding: 'bscn',
            animal: 'bear',
            explorer: 'https://bscscan.com',
            scan: {
                coin: {
                    name: 'Pluri',
                    url: 'https://bscscan.com/token/0x719D6FB3FcEFFb720e5d650F9766E5880090bCF7',
                    address: '0x719D6FB3FcEFFb720e5d650F9766E5880090bCF7'
                },
                faucet: {
                    name: 'Faucet',
                    url: 'https://bscscan.com/address/0xe469e49A15cF9c6B0C2685027eBb8bE43363bE33',
                    address: '0xe469e49A15cF9c6B0C2685027eBb8bE43363bE33'
                },
                stakeholder: {
                    name: 'Stakeholder',
                    url: 'https://bscscan.com/address/0x5D3B60c072F89DAfBF00A088b7268948C59acdEe',
                    address: '0x5D3B60c072F89DAfBF00A088b7268948C59acdEe'
                }
            }
        },
        {
            name: 'Avalanche C-Chain',
            id: 43114,
            chainId: 43114,
            icon: 'avalanche',
            slug: 'avalanche',
            nativeCurrency: {
                name: 'AVAX',
                symbol: 'AVAX',
                decimals: 18,
            },
            stableToken: {
                name: 'USDT',
                symbol: 'USDT',
                decimals: 6,
            },
            rpcUrl: 'https://avalanche-c-chain-rpc.publicnode.com',
            image: 'avalanche.png',
            domainEnding: 'avc',
            animal: 'yeti',
            explorer: 'https://snowtrace.io',
            scan: {
                coin: {
                    name: 'Pluri',
                    url: 'https://snowtrace.io/token/0x2901F652F2640BF09a54752998426A01e1c9aAc6?chainId=43114',
                    address: '0x2901F652F2640BF09a54752998426A01e1c9aAc6'
                },
                faucet: {
                    name: 'Faucet',
                    url: 'https://snowtrace.io/address/0x719D6FB3FcEFFb720e5d650F9766E5880090bCF7?chainId=43114',
                    address: '0x719D6FB3FcEFFb720e5d650F9766E5880090bCF7'
                },
                stakeholder: {
                    name: 'Stakeholder',
                    url: 'https://snowtrace.io/address/0x55DFE94bD09f283FF0DC021b8cB41558786Ab053?chainId=43114',
                    address: '0x55DFE94bD09f283FF0DC021b8cB41558786Ab053'
                }
            }
        },
        {
            name: 'Ethereum',
            id: 1,
            chainId: 1,
            icon: 'ethereum',
            slug: 'ethereum',
            nativeCurrency: {
                name: 'ETH',
                symbol: 'ETH',
                decimals: 18,
            },
            stableToken: {
                name: 'USDT',
                symbol: 'USDT',
                decimals: 6,
            },
            rpcUrl: 'https://ethereum-rpc.publicnode.com',
            image: 'ethereum.png',
            domainEnding: 'ethr',
            animal: 'bull',
            scan: {
                coin: {
                    name: 'Pluri',
                    url: 'https://etherscan.io/token/0x6337c3e9c04A0fC81e8cF689029eFaD88D67Fe2D',
                    address: '0x6337c3e9c04A0fC81e8cF689029eFaD88D67Fe2D'
                },
                faucet: {
                    name: 'Faucet',
                    url: 'https://etherscan.io/address/0xd7d18126BE77fDfc4C8879c14A7f9a6601416D1B',
                    address: '0xd7d18126BE77fDfc4C8879c14A7f9a6601416D1B'
                },
                stakeholder: {
                    name: 'Stakeholder',
                    url: 'https://etherscan.io/address/0x7c20BB233339D83656E890EE28870e038C8b68a0',
                    address: '0x7c20BB233339D83656E890EE28870e038C8b68a0'
                }
            }

        },
        /*

    
        /*   {
              name: 'Ethereum Classic',
              id: 61,
              chainId: 61,
              icon: 'ethereumclassic',
              slug: 'ethereumclassic',
              nativeCurrency: {
                  name: 'ETC',
                  symbol: 'ETC',
                  decimals: 18,
              },
              stableToken: {
                  name: 'USDT',
                  symbol: 'USDT',
                  decimals: 6,
              },
              rpcUrl: 'https://etc.rivet.link',
              image: 'ethereumclassic.png',
              domainEnding: 'ethrc',
              animal: 'ragingbull'
          }, */
        {
            name: 'Arbitrum',
            id: 42161,
            chainId: 42161,
            icon: 'arbitrum',
            slug: 'arbitrum',
            nativeCurrency: {
                name: 'ETH',
                symbol: 'ETH',
                decimals: 18,
            },
            stableToken: {
                name: 'USDT',
                symbol: 'USDT',
                decimals: 6,
            },
            rpcUrl: 'https://arbitrum-one-rpc.publicnode.com',
            image: 'arbitrum.png',
            domainEnding: 'arb',
            animal: 'unicorn',
            explorer: 'https://arbiscan.io',
            scan: {
                coin: {
                    name: 'Pluri',
                    url: 'https://arbiscan.io/token/0x8772dDF9b63fE761b4e316082781517F6832F9ca',
                    address: '0x8772dDF9b63fE761b4e316082781517F6832F9ca'
                },
                faucet: {
                    name: 'Faucet',
                    url: 'https://arbiscan.io/address/0xce5908510d568c0eE18e62B79b199a4B21a022eB',
                    address: '0xce5908510d568c0eE18e62B79b199a4B21a022eB'
                },
                stakeholder: {
                    name: 'Stakeholder',
                    url: 'https://arbiscan.io/address/0xe3ba04fA23A8276e0C9B8A668e4b5628bA7c0E96',
                    address: '0xe3ba04fA23A8276e0C9B8A668e4b5628bA7c0E96'
                }
            }
        },
        {
            name: 'Optimism',
            id: 10,
            chainId: 10,
            icon: 'optimism',
            slug: 'optimism',
            nativeCurrency: {
                name: 'ETH',
                symbol: 'ETH',
                decimals: 18,
            },
            stableToken: {
                name: 'USDT',
                symbol: 'USDT',
                decimals: 6,
            },
            rpcUrl: 'https://optimism-rpc.publicnode.com',
            image: 'optimism.png',
            domainEnding: 'opt',
            animal: 'dragon',
            explorer: 'https://optimistic.etherscan.io',
            scan: {
                coin: {
                    name: 'Pluri',
                    url: 'https://optimistic.etherscan.io/token/0xe3ba04fA23A8276e0C9B8A668e4b5628bA7c0E96',
                    address: '0xe3ba04fA23A8276e0C9B8A668e4b5628bA7c0E96'
                },
                faucet: {
                    name: 'Faucet',
                    url: 'https://optimistic.etherscan.io/address/0x9D68671a77419E5C22BE70881F8D728b6a2dD1FD',
                    address: '0x9D68671a77419E5C22BE70881F8D728b6a2dD1FD'
                },
                stakeholder: {
                    name: 'Stakeholder',
                    url: 'https://optimistic.etherscan.io/address/0xce5908510d568c0eE18e62B79b199a4B21a022eB',
                    address: '0xce5908510d568c0eE18e62B79b199a4B21a022eB'
                }
            }
        },
        {
            name: 'Fantom',
            id: 250,
            chainId: 250,
            icon: 'fantom',
            slug: 'fantom',
            nativeCurrency: {
                name: 'FTM',
                symbol: 'FTM',
                decimals: 18,
            },
            stableToken: {
                name: 'USDT',
                symbol: 'USDT',
                decimals: 6,
            },
            rpcUrl: 'https://fantom-rpc.publicnode.com',
            image: 'fantom.png',
            domainEnding: 'ftm',
            animal: 'ghost',
            explorer: 'https://ftmscan.com',
            scan: {
                coin: {
                    name: 'Pluri',
                    url: 'https://ftmscan.com/token/0x8772dDF9b63fE761b4e316082781517F6832F9ca',
                    address: '0x8772dDF9b63fE761b4e316082781517F6832F9ca'
                },
                faucet: {
                    name: 'Faucet',
                    url: 'https://ftmscan.com/address/0xce5908510d568c0eE18e62B79b199a4B21a022eB',
                    address: '0xce5908510d568c0eE18e62B79b199a4B21a022eB'
                },
                stakeholder: {
                    name: 'Stakeholder',
                    url: 'https://ftmscan.com/address/0xe3ba04fA23A8276e0C9B8A668e4b5628bA7c0E96',
                    address: '0xe3ba04fA23A8276e0C9B8A668e4b5628bA7c0E96'
                }
            }
        },
    ],
    contractsLoaded: false,
    expectedChainList: [

        {
            name: 'Binance',
            id: 56,
            chainId: 56,
            icon: 'binance',
            slug: 'binance',
            nativeCurrency: {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18,
            },
            stableToken: {
                name: 'USDT',
                symbol: 'USDT',
                decimals: 6,
            },
            rpcUrl: 'https://binance.llamarpc.com',
            image: 'binance.png',
            domainEnding: 'bscn',
            animal: 'bear'
        },
        {
            name: 'Ethereum',
            id: 1,
            chainId: 1,
            icon: 'ethereum',
            slug: 'ethereum',
            nativeCurrency: {
                name: 'ETH',
                symbol: 'ETH',
                decimals: 18,
            },
            stableToken: {
                name: 'USDT',
                symbol: 'USDT',
                decimals: 6,
            },
            rpcUrl: 'https://etc.rivet.link',
            image: 'ethereum.png',
            domainEnding: 'ethr',
            animal: 'bull'

        },
        {
            name: 'Avalanche C-Chain',
            id: 43114,
            chainId: 43114,
            icon: 'avalanche',
            slug: 'avalanche',
            nativeCurrency: {
                name: 'AVAX',
                symbol: 'AVAX',
                decimals: 18,
            },
            stableToken: {
                name: 'USDT',
                symbol: 'USDT',
                decimals: 6,
            },
            rpcUrl: 'https://avalanche-c-chain-rpc.publicnode.com',
            image: 'avalanche.png',
            domainEnding: 'avc',
            animal: 'yeti'
        },
        {
            name: 'Ethereum Classic',
            id: 61,
            chainId: 61,
            icon: 'ethereumclassic',
            slug: 'ethereumclassic',
            nativeCurrency: {
                name: 'ETC',
                symbol: 'ETC',
                decimals: 18,
            },
            stableToken: {
                name: 'USDT',
                symbol: 'USDT',
                decimals: 6,
            },
            rpcUrl: 'https://etc.rivet.link',
            image: 'ethereumclassic.png',
            domainEnding: 'ethrc',
            animal: 'ragingbull'
        },
        {
            name: 'Arbitrum',
            id: 42161,
            chainId: 42161,
            icon: 'arbitrum',
            slug: 'arbitrum',
            nativeCurrency: {
                name: 'ETH',
                symbol: 'ETH',
                decimals: 18,
            },
            stableToken: {
                name: 'USDT',
                symbol: 'USDT',
                decimals: 6,
            },
            rpcUrl: 'https://arbitrum-one-rpc.publicnode.com',
            image: 'arbitrum.png',
            domainEnding: 'arb',
            animal: 'unicorn'
        },
        {
            name: 'Optimism',
            id: 10,
            chainId: 10,
            icon: 'optimism',
            slug: 'optimism',
            nativeCurrency: {
                name: 'ETH',
                symbol: 'ETH',
                decimals: 18,
            },
            stableToken: {
                name: 'USDT',
                symbol: 'USDT',
                decimals: 6,
            },
            rpcUrl: 'https://optimism-rpc.publicnode.com',
            image: 'optimism.png',
            domainEnding: 'opt',
            animal: 'dragon'
        },
        {
            name: 'Fantom',
            id: 250,
            chainId: 250,
            icon: 'fantom',
            slug: 'fantom',
            nativeCurrency: {
                name: 'FTM',
                symbol: 'FTM',
                decimals: 18,
            },
            stableToken: {
                name: 'USDT',
                symbol: 'USDT',
                decimals: 6,
            },
            rpcUrl: 'https://fantom-rpc.publicnode.com',
            image: 'fantom.png',
            domainEnding: 'ftm',
            animal: 'ghost'
        }
    ]
})

const chainSlice = createSlice({
    name: 'Chain Manager',
    initialState: initialState(),
    reducers: {
        resetChain: state => initialState(),
        setCurrentActiveChain: (state, action) => {
            state.currentChain = action.payload
        },
        setAvailableChains: (state, action) => {
            state.chainList = action.payload
        },
        setContractsLoaded: (state, action) => {
            state.contractsLoaded = action.payload
        }
    }
})

export const {
    resetChain,
    setCurrentActiveChain,
    setAvailableChains,
    setContractsLoaded
} = chainSlice.actions



export default chainSlice.reducer